const calcularDigito = (base, peso) => {
  let soma = 0;
  for (let i = 0; i < base.length; i++) {
    soma += parseInt(base.charAt(i)) * (peso - i);
  }
  const resto = soma % 11;
  return resto < 2 ? 0 : 11 - resto;
};

export const testCpf = (cpf) => {
  cpf = cpf.replace(/\D/g, "");

  if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) return false;

  const digito1 = calcularDigito(cpf.substring(0, 9), 10);
  const digito2 = calcularDigito(cpf.substring(0, 9) + digito1, 11);

  return (
    digito1 === parseInt(cpf.charAt(9)) && digito2 === parseInt(cpf.charAt(10))
  );
};
